<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.owner_branch") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                v-if="Object.keys(model_header).length"
                size="small"
                class="p-2"
                type="primary"
                @click="drawer.update.status = true"
                icon="el-icon-edit"
                circle
              ></el-button>
              <el-button
                v-if="!Object.keys(model_header).length"
                size="small"
                class="p-2"
                type="primary"
                @click="drawerCreate = true"
                icon="el-icon-plus"
                circle
              ></el-button>

              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitTransaction(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item :label="columns.name.title" prop="name">
            <crm-input
              v-model="form.name"
              :inputValue="form.name"
              autocomplete="off"
            ></crm-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <div class="imgUpload" style="margin-top: 0px !important">
            <el-upload
              :action="base_url + 'owner-branch/store-image'"
              :limit="1"
              accept="image/*"
              name="image"
              list-type="picture-card"
              :on-remove="handleRemove"
              :file-list="imageList"
              :on-success="handleAvatarSuccess"
              :headers="{
                Authorization: 'Bearer ' + $store.getters.token,
              }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100px" :src="dialogImageUrl" alt />
            </el-dialog>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="m-1">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <el-input
            placeholder="Поиск"
            ref="search1"
            class="my-kh-search"
            size="small"
            v-model="search1"
          ></el-input>
          <el-tree
            class="filter-tree"
            :data="list"
            show-checkbox
            node-key="id"
            @check-change="selectedItem"
            :filter-node-method="filterNode"
            ref="items"
            :expand-on-click-node="true"
          >
            <span
              style="line-height: 150px"
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <span class="aaa my-tree-khan m-05">{{ data.name }}</span>
            </span>
          </el-tree>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <el-input
            placeholder="Поиск"
            ref="search2"
            class="my-kh-search"
            size="small"
            v-model="search2"
          ></el-input>
          <div class="block">
            <el-tree
              class="filter-tree"
              :data="service"
              show-checkbox
              node-key="id"
              @check-change="selectedService"
              :filter-node-method="filterNode"
              ref="services"
              :expand-on-click-node="true"
            >
              <span
                style="line-height: 150px"
                class="custom-tree-node"
                slot-scope="{ node, data }"
              >
                <span class="aaa my-tree-khan m-05">
                  <div class="w-200">{{ data.name }}</div>
                </span>
              </span>
            </el-tree>
          </div>
        </el-col></el-row
      >
    </el-form>

    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="60%"
      :append-to-body="true"
      :wrapperClosable="false"
      :drawer="drawerCreate"
      :ref="drawer.create.name"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create
          @c-close="closeDrawer"
          :drawer-name="drawer.create.name"
          :id="form.id"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      size="60%"
      :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      :append-to-body="true"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
          :id="form.id"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import CrmCreate from "./mini-header/crm-create";
import CrmUpdate from "./mini-header/crm-update";
export default {
  mixins: [form, drawerChild, drawer],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  components: {
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      url: process.env.VUE_APP_URL_DOCS,
      data: [],
      services: [],
      fileList: [],
      imageList: [],
      fullImageList: [],
      dialogImageUrl: "",
      dialogFullImageUrl: "",
      dialogVisible: false,
      dialogVisibleFull: false,
      owner_clinic_image_id: null,
      drawerPatientService: false,
      drawerCreate: false,
      drawerUpdate: false,
      search1: "",
      search2: "",
      selectedServices: [],
      selectedList: [],
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },

  created() {
    this.getUsers();
    const query = { relation: true };
    this.servicesRealtionList(query);
  },

  computed: {
    ...mapGetters({
      model_header: "ownerBranchHeaders/model",
      model: "ownerBranches/model",
      rules: "ownerBranches/rules",
      columns: "ownerBranches/columns",
      service: "services/relation_list",
      list: "users/list",
    }),
  },

  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
    search1(val) {
      this.$refs.items.filter(val);
    },
    search2(val) {
      this.$refs.services.filter(val);
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      save: "ownerBranches/update",
      getUsers: "users/index",
      servicesRealtionList: "services/relationList",
      destroy: "ownerBranches/destroyImage",
    }),
    async afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));

      if (this.form.image) {
        this.form.image = this.url + this.form.image.image;
      }

      if (this.form.image) {
        this.imageList.push({
          url: this.form.image,
        });
      }

      await this.$refs.services.setCheckedNodes(
        this.form.services.filter((item) => item.is_parent == false)
      );
      await this.$refs.items.setCheckedNodes(this.form.doctors);
    },
    closeDrawer(drawer) {
      this.drawer.update.status = false;
      this.drawerCreate = false;
      this[drawer.drawer] = false;
      if (this[drawer.emptyModel]) {
        this.emptyModel();
      }
    },
    emptyModel() {},

    afterClosed() {
      this.resetForm("form");

      this.data = [];
    },
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    handlePictureCardPreviewFull(file) {
      this.dialogFullImageUrl = file.url;
      this.dialogVisibleFull = true;
    },
    handleRemove(file, fileList) {
      this.destroy(file.response.data.image.id).then((res) => {});
    },
    handleAvatarSuccess(res, file) {
      this.form.owner_branch_image_id = res.data.image.id;
    },
    handleAvatarSuccessFull(res, file) {
      this.form.owner_branch_image_id = res.data.image.id;
    },
    submitTransaction(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.doctors = JSON.parse(JSON.stringify(this.selectedList));
          this.form.services = JSON.parse(
            JSON.stringify(this.selectedServices)
          );
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.parent().listChanged();
              this.resetForm("form");
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    selectedService() {
      this.selectedServices = [];
      let half_item = this.$refs.services.getHalfCheckedNodes();
      let item = this.$refs.services.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          this.selectedServices.push(element);
        }
      }
      for (const i in half_item) {
        if (half_item[i]) {
          const element = half_item[i];
          this.selectedServices.push(element);
        }
      }
    },
    reloadIfChanged(modal) {
      this[modal] = false;
    },
    selectedItem() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          this.selectedList.push(element);
        }
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    resetForm(formName) {
      this.data = [];
      this.imageList = [];
      this.fullImageList = [];
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
<style>
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>